import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MessageCampaign } from '../shared/services/message-campaigns';
import { MessageBusService } from '../../../common/services/message-bus/message-bus.service';
import { MessageType } from '../../../common/services/message-bus/message';
import { MessageCampaignStateManager } from '../shared/services/message-campaign-state.manager';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FaIconComponent,
    MatButtonModule,
  ],
  standalone: true,
})
export class PopupMessageComponent implements OnInit {

  constructor(private _dialog: MatDialogRef<PopupMessageComponent>,
              private _messageBus: MessageBusService,
              private _campaignStateManager: MessageCampaignStateManager,
              @Inject(MAT_DIALOG_DATA) private _dialogData: { campaign: MessageCampaign }) {
  }

  public campaign!: MessageCampaign;

  ngOnInit(): void {
    this.campaign = this._dialogData.campaign;
  }

  public onButtonClick() {
    this._campaignStateManager.increaseClickCount(this.campaign.id);

    this._messageBus.dispatch({ type: MessageType.ClickMessageCampaign, payload: { campaign: this.campaign.name } });
  }

  public close() {
    this._messageBus.dispatch({ type: MessageType.CloseMessageCampaign, payload: { campaign: this.campaign.name } });

    this._dialog.close();
  }

  protected readonly faTimes = faTimes;
}
