<mat-form-field class="form-field-small" subscriptSizing="dynamic" appearance="outline">
  <input
    type="text"
    placeholder="{{ 'Common.Search' | translate }}..."
    [attr.aria-label]="'Common.Search' | translate"
    matInput
    [formControl]="searchBarControl"
    [matAutocomplete]="auto"
  >

  <mat-icon matPrefix>search</mat-icon>

  @if (searchBarControl.value) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="searchBarControl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  }

  <mat-autocomplete #auto="matAutocomplete">
    @for (result of searchResults$ | async; track result) {
      <mat-option
        (onSelectionChange)="onSearchResultSelectedAsync(result)"
        [disabled]="result.type === 'none'"
      >
        @if (result.type === 'user' && result.result) {
          <app-employee-tag [employee]="result.result"></app-employee-tag>
        }
        @if (result.type === 'none') {
          <div class="text-muted">{{ 'Header.SearchBar.NoResultsFound' | translate }}</div>
        }
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
