import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { RouteNavigationService } from '../../common/routing/route-navigation.service';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '../../common/services/injection-tokens';
import { LogoComponent } from '../../common/components/logo/logo.component';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LogoComponent,
    RouterLink,
    TranslateModule,
  ],
})
export class FooterComponent implements OnInit {
  constructor(private _routeNavigationService: RouteNavigationService,
              @Inject(ENVIRONMENT) private _environment: Environment) {
  }

  public year = new Date().getFullYear();

  public privacyUrl: string = this._routeNavigationService.getUrlForPrivacyPolicy();
  public termsUrl: string = this._routeNavigationService.getUrlForTermsAndConditions();
  public pricingUrl: string = this._routeNavigationService.getUrlForPricing();
  public contactUrl = this._environment.social?.emailUrl;

  ngOnInit(): void {

  }
}
