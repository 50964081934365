@if (campaign) {
  <div class="p-2 text-center position-relative" style="max-width: 450px">
    <div class="position-absolute p-1" style="top: -10px; right: -10px;">
      <div class="cursor-pointer" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </div>
    </div>
    <h2>{{ campaign.content.title }}</h2>
    <div class="mt-3">{{ campaign.content.body }}</div>
    <div class="mt-4">
      <a
        mat-flat-button
        color="primary"
        class="p-2 px-3"
        style="text-transform: none"
        (click)="onButtonClick()"
        [href]="campaign.content.buttonLink"
        [attr.target]="campaign.content.buttonTarget"
      >
        {{ campaign.content.buttonText }}
      </a>
    </div>
  </div>
}
