import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureAvailableDirective } from '../../../common/directives/feature-available.directive';

@UntilDestroy()
@Component({
  selector: 'app-header-menu-items',
  templateUrl: './header-menu-items.component.html',
  styleUrls: ['./header-menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    FeatureAvailableDirective,
  ],
})
export class HeaderMenuItemsComponent implements OnInit {

  constructor(private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {

  }

  @Input()
  public direction: 'horizontal' | 'vertical' = 'horizontal';

  public isUserLoggedIn: boolean = false;

  public pricingUrl = this._routeNavigationService.getUrlForPricing();
  public dashboardUrl = this._routeNavigationService.getUrlForDashboard();
  public organizationUrl = this._routeNavigationService.getUrlForOrganization();
  public wikiUrl = this._routeNavigationService.getUrlForWiki();
  public goalsUrl = this._routeNavigationService.getUrlForCompanyGoals();
  public surveysUrl = this._routeNavigationService.getUrlForSurveys();
  public companySettingsUrl = this._routeNavigationService.getUrlForCompanySettings();

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.isUserLoggedIn = !!state.user;

        this._changeDetector.markForCheck();
      });
  }
}
