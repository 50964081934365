import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { EmployeeLimitedDTO } from '../../../../../../server/src/companies/dto/employee.dto';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EmployeesService } from '../../../common/services/employees/employees.service';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EmployeeTagComponent } from '../../../common/components/employee-tag/employee-tag.component';
import { AsyncPipe } from '@angular/common';

export interface SearchBarResult {
  type: 'user' | 'none';

  result: EmployeeLimitedDTO | null;
}

@UntilDestroy()
@Component({
  selector: 'app-header-search-bar',
  templateUrl: './header-search-bar.component.html',
  styleUrls: ['./header-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    EmployeeTagComponent,
    AsyncPipe,
  ],
})
export class HeaderSearchBarComponent implements OnInit {

  constructor(private _routeNavigationService: RouteNavigationService,
              private _employeesService: EmployeesService,
              private _changeDetector: ChangeDetectorRef) {

  }

  public searchBarControl = new UntypedFormControl();
  public searchResults$?: Observable<SearchBarResult[]>;

  public isUserLoggedIn: boolean = false;

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.isUserLoggedIn = !!state.user;

        this._changeDetector.markForCheck();
      });

    this.searchResults$ = this.searchBarControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap<string, Promise<SearchBarResult[]>>(async searchTerm => {
          if (!searchTerm) {
            return [];
          }

          const foundEmployees = await this._employeesService.getEmployeesAsync({
            searchTerm: searchTerm,
          });

          if (!foundEmployees.length) {
            return [{ type: 'none', result: null }];
          }

          return foundEmployees.map(_ => {
            return {
              type: 'user',
              result: _,
            };
          });
        }),
        untilDestroyed(this),
      );
  }

  public async onSearchResultSelectedAsync(result: SearchBarResult) {
    switch (result.type) {
      case 'user':
        await this._routeNavigationService.goToEmployeePageAsync(result.result!.id);
        break;

      case 'none':
        break;

      default:
        throw new Error(`Unknown result type: ${ result.type }`);
    }
  }
}
