import { Injectable } from '@angular/core';
import { MessageBusService } from '../../../../common/services/message-bus/message-bus.service';
import { MessageCampaign, MessageCampaigns, MessageCampaignState, MessageTriggerRestrictionType } from './message-campaigns';
import { filter, map } from 'rxjs/operators';
import { DateUtils } from '../../../../../../../common/utils/date.utils';
import { MessageType } from '../../../../common/services/message-bus/message';
import { PopupMessageComponent } from '../../popup-message/popup-message.component';
import { MessageCampaignStateManager } from './message-campaign-state.manager';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class MessageCampaignsService {
  constructor(private _messageBus: MessageBusService,
              private _campaignStateManager: MessageCampaignStateManager,
              private _dialogService: MatDialog) {

  }

  public initializeMessageCampaigns() {
    for (const campaign of MessageCampaigns) {
      this._setupCampaign(campaign);
    }
  }

  private _setupCampaign(campaign: MessageCampaign) {
    this._messageBus.subscribeToMultiple(campaign.triggerEvents.map(trigger => trigger.messageType))
      .pipe(
        filter(message => {
          const trigger = campaign.triggerEvents.find(t => t.messageType === message.type);

          if (!trigger) {
            return false;
          }

          if (!trigger.payloadFilter) {
            return true;
          }

          return trigger.payloadFilter(message.payload);
        }),
        map(() => this._campaignStateManager.getCampaignState(campaign.id)),
        filter(campaignState => {
          return !campaign.triggerRestrictions.some(restriction => !this._isRestrictionPassing(restriction, campaignState));
        }),
      ).subscribe(() => {
      this._showCampaignMessage(campaign);
    });
  }

  private _showCampaignMessage(campaign: MessageCampaign) {
    this._campaignStateManager.saveShown(campaign.id);

    this._messageBus.dispatch({ type: MessageType.ShowMessageCampaign, payload: { campaign: campaign.name } });

    this._dialogService.open(PopupMessageComponent, {
      disableClose: true,
      data: {
        campaign: campaign,
      },
    });
  }

  private _isRestrictionPassing(restriction: {
    type: MessageTriggerRestrictionType;
    value?: number | Date
  }, campaignState: MessageCampaignState): boolean {
    switch (restriction.type) {
      case MessageTriggerRestrictionType.DateAfter:
        return new Date().getTime() >= (<Date>restriction.value).getTime();

      case MessageTriggerRestrictionType.DateBefore:
        return new Date().getTime() <= (<Date>restriction.value).getTime();

      case MessageTriggerRestrictionType.MinDaysSinceLastShow:
        return !campaignState.lastShown || (DateUtils.getDaysBetween(campaignState.lastShown, new Date()) >= <number>restriction.value);

      case MessageTriggerRestrictionType.MaxShown:
        return campaignState.numberOfShows < <number>restriction.value;

      case MessageTriggerRestrictionType.MaxButtonClicked:
        return campaignState.numberOfClicks < <number>restriction.value;

      default:
        return false;
    }
  }
}
