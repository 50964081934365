import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { HeaderSearchBarComponent } from '../header-search-bar/header-search-bar.component';
import { HeaderMenuItemsComponent } from '../header-menu-items/header-menu-items.component';
import { MatListModule } from '@angular/material/list';
import { AccountMenuComponent } from '../account-menu/account-menu.component';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@UntilDestroy()
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    HeaderSearchBarComponent,
    HeaderMenuItemsComponent,
    MatListModule,
    AccountMenuComponent,
    RouterLink,
    MatButtonModule,
  ],
})
export class SideMenuComponent implements OnInit {

  constructor(private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {

  }

  public isOpen: boolean = false;

  public isUserLoggedIn: boolean = false;
  public hasCompany: boolean = false;

  public loginUrl: string = this._routeNavigationService.getUrlForLogin();
  public registerUrl: string = this._routeNavigationService.getUrlForRegistration();

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.isUserLoggedIn = !!state.user;
        this.hasCompany = !!state.company;

        this._changeDetector.markForCheck();
      });
  }

  public toggle() {
    this.isOpen = !this.isOpen;

    this._changeDetector.markForCheck();
  }

  public open() {
    this.isOpen = true;

    this._changeDetector.markForCheck();
  }

  public close() {
    this.isOpen = false;

    this._changeDetector.markForCheck();
  }
}
