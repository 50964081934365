<header class="flex-row-center px-3">
  <div class="flex-grow-1 flex-row-center">
    <div class="flex-row-center hover-fade me-2 me-xl-3" style="width: 160px" [routerLink]="landingUrl">
      @if (!customLogoUrl) {
        <app-logo />
      }

      @if (customLogoUrl) {
        <img class="img-fluid" [src]="customLogoUrl">
      }
    </div>

    <div class="flex-row-center flex-grow-1 d-none d-xl-flex">
      @if (hasCompany) {
        <app-header-search-bar class="ms-3"></app-header-search-bar>
      }

      <app-header-menu-items class="mx-3" direction="horizontal"></app-header-menu-items>

      <div class="flex-grow-1 flex-row-center justify-content-end">
        @if (isUserLoggedIn) {
          <app-account-menu></app-account-menu>
        }

        @if (!isUserLoggedIn) {
          <a class="nav-item" [routerLink]="loginUrl">
            {{ 'Header.SignIn' | translate }}
          </a>
          <a class="nav-item nav-item-bordered ms-3" [routerLink]="registerUrl">
            {{ 'Header.SignUp' | translate }}
          </a>
        }
      </div>
    </div>

    <div class="flex-grow-1 text-end d-xl-none">
      <button mat-icon-button class="mat-icon-button-big mat-elevation-z2" (click)="sideMenu.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <app-side-menu #sideMenu></app-side-menu>
    </div>
  </div>
</header>
