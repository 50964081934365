import { Inject, Injectable, Injector } from '@angular/core';
import { LoggerService } from '../../../../common/services/logger/logger.service';
import { AuthenticationService } from '../../../../common/services/authentication.service';
import { TrackingService } from '../../../../common/services/tracking/tracking.service';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MessageBusService } from '../../../../common/services/message-bus/message-bus.service';
import { MessageType } from '../../../../common/services/message-bus/message';
import { MessageCampaignsService } from '../../../messaging/shared/services/message-campaigns.service';
import { CoolHttp, HttpHeader } from '@angular-cool/http';
import { ENVIRONMENT, WINDOW } from '../../../../common/services/injection-tokens';
import { Environment } from '../../../../../environments/environment.interface';
import { UnauthenticatedInterceptor } from './unauthnticated.interceptor';
import { TranslationService } from '../translation/translation.service';
import { setInjector } from '../../../../common/utils/injector.utils';

export function startupServiceFactory(provider: StartupService) {
  return () => provider.setupAsync();
}

@Injectable()
export class StartupService {
  constructor(
    @Inject(ENVIRONMENT) private _environment: Environment,
    @Inject(WINDOW) private _window: Window,
    private _translationService: TranslationService,
    private _authenticationService: AuthenticationService,
    private _trackingService: TrackingService,
    private _loggerService: LoggerService,
    private _messageBus: MessageBusService,
    private _unauthenticatedInterceptor: UnauthenticatedInterceptor,
    private _http: CoolHttp,
    private _messageCampaignsService: MessageCampaignsService,
    private _router: Router,
    injector: Injector,
  ) {
    setInjector(injector);
  }

  public async setupAsync() {
    this._loggerService.initialize();

    this._setupHttp();

    this._translationService.setup();

    // Do not wait for this
    this._asyncSetupAsync();

    this._trackingService.initialize();

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const routeRoot = this._router.routerState.snapshot.root;

          let route: ActivatedRouteSnapshot = routeRoot.firstChild!;

          while (route?.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this._messageBus.dispatch({ type: MessageType.PageView, payload: { page: route.data?.page } });
      });

    this._messageCampaignsService.initializeMessageCampaigns();
  }

  private async _asyncSetupAsync() {
    await this._tryGetXSRFTokenAsync();

    await this._authenticationService.initializeAsync();
  }

  private async _tryGetXSRFTokenAsync() {
    const response = <{ token: string; }>await this._http.postAsync('api/settings/xsrf-token');

    if (this._window.location.hostname === 'localhost') {
      this._http.registerGlobalHeader(new HttpHeader('X-XSRF-TOKEN', response.token));
    } else {
      this._http.sendCookieValueInCustomHeader('XSRF-TOKEN', 'X-XSRF-TOKEN');
    }
  }

  private _setupHttp() {
    this._http.setWithCredentials(true);
    this._http.registerBaseUrl(this._environment.backendUrl);

    this._http.registerResponseInterceptor(this._unauthenticatedInterceptor);
  }
}
