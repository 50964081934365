<div class="h-100">
  <div style="min-height: 100%" class="d-flex flex-column">
    @if (!hideHeader) {
      <div id="header-container">
        <app-header />
      </div>
    }

    <div id="page-container">
      <router-outlet />
    </div>
  </div>

  <app-footer />

</div>
