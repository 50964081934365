<button mat-button [matMenuTriggerFor]="menu" class="mat-elevation-z0">
  <div class="flex-row-center">
    @if (data?.photoUrl) {
      <img [src]="data?.photoUrl">
    }

    <div class="ms-2 user-select-none">{{ data?.name }}</div>

    <fa-icon class="ms-2" [icon]="faCaretDown"></fa-icon>
  </div>
</button>

<mat-menu #menu="matMenu" xPosition="after">
  @if (data) {
    <button mat-menu-item (click)="myProfile()">
      <mat-icon>person</mat-icon>
      <span>{{ 'Header.AccountMenu.MyProfile' | translate }}</span>
    </button>
  }

  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>

    <span>{{ 'Header.AccountMenu.SignOut' | translate }}</span>
  </button>
</mat-menu>

