import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../common/routing/route-navigation.service';
import { Observable } from 'rxjs';
import { ApplicationState, ApplicationStateModel } from '../../app.state';
import { Select } from '@ngxs/store';
import { FeaturePermission } from '../../../../../server/src/companies/model/feature-permissions.interface';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LogoComponent } from '../../common/components/logo/logo.component';
import { RouterLink } from '@angular/router';
import { HeaderSearchBarComponent } from './header-search-bar/header-search-bar.component';
import { HeaderMenuItemsComponent } from './header-menu-items/header-menu-items.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SideMenuComponent } from './side-menu/side-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LogoComponent,
    RouterLink,
    HeaderSearchBarComponent,
    HeaderMenuItemsComponent,
    AccountMenuComponent,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    SideMenuComponent,
  ],
})
export class HeaderComponent implements OnInit {
  constructor(private _changeDetector: ChangeDetectorRef,
              private _routeNavigationService: RouteNavigationService) {

  }

  public isUserLoggedIn: boolean = false;
  public hasCompany: boolean = false;

  public landingUrl: string = this._routeNavigationService.getUrlForLanding();

  public loginUrl: string = this._routeNavigationService.getUrlForLogin();
  public registerUrl: string = this._routeNavigationService.getUrlForRegistration();

  public customLogoUrl?: string;

  @SelectSnapshot(ApplicationState.isFeatureEnabled(FeaturePermission.WhiteLabeling))
  public companyHasWhiteLabeling!: boolean;

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.isUserLoggedIn = !!state.user;
        this.hasCompany = !!state.company;

        if (state.company &&
          state.company.whiteLabel?.logoEnabled &&
          state.company.whiteLabel?.logo &&
          this.companyHasWhiteLabeling) {
          this.customLogoUrl = state.company.whiteLabel.logo.url;
        } else {
          this.customLogoUrl = undefined;
        }

        this._changeDetector.markForCheck();
      });
  }
}
