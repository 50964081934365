import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { MessageType } from '../../../common/services/message-bus/message';
import { TriggerMessage } from '../../../common/decorators/trigger-message.decorator';
import { MatMenuModule } from '@angular/material/menu';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@UntilDestroy()
@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenuModule,
    MatIconModule,
    FaIconComponent,
    TranslateModule,
    MatButtonModule,
  ],
})
export class AccountMenuComponent implements OnInit {
  private _employeeId?: string;

  constructor(private _authenticationService: AuthenticationService,
              private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {

  }

  public data?: {
    name?: string;

    photoUrl?: string;
  };

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.data = {
          name: state.employee?.name || state.user?.name,
          photoUrl: state.employee?.photo?.url || state.user?.photo?.url,
        };

        this._employeeId = state.employee?.id;

        this._changeDetector.markForCheck();
      });
  }

  @TriggerMessage(MessageType.Logout)
  public async logout() {
    await this._authenticationService.logoutAsync();
  }

  public async myProfile() {
    if (!this._employeeId) {
      return;
    }

    await this._routeNavigationService.goToEmployeePageAsync(this._employeeId);
  }

  protected readonly faCaretDown = faCaretDown;
}
