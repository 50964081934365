<div class="side-menu mat-elevation-z4" [class.open]="isOpen">
  <div>
    <button mat-icon-button class="mat-icon-button-big" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="mt-3">
    @if (hasCompany) {
      <app-header-search-bar></app-header-search-bar>
    }

    <div class="mt-4">
      <app-header-menu-items direction="vertical" (click)="close()"></app-header-menu-items>
    </div>

    <mat-divider class="my-3 mat-divider-white"></mat-divider>

    @if (isUserLoggedIn) {
      <div class="pt-3">
        <app-account-menu></app-account-menu>
      </div>
    }

    @if (!isUserLoggedIn) {
      <a class="nav-item" [routerLink]="loginUrl" (click)="close()">Sign in</a>
      <a class="nav-item nav-item-bordered" [routerLink]="registerUrl" (click)="close()">Sign up</a>
    }
  </div>
</div>
