<footer class="p-3">
  <div class="text-center text-small text-muted">
    <app-logo></app-logo>

    <div class="mt-2">
      &copy; {{ year }} WorkLeaps, Amill Kft. All rights reserved.
    </div>

    <div class="mt-2">
      <a class="mx-2 text-muted" [routerLink]="pricingUrl">{{ 'Footer.Pricing' | translate }}</a>

      <a class="mx-2 text-muted" [routerLink]="termsUrl">{{ 'Footer.Terms' | translate }}</a>

      <a class="mx-2 text-muted" [routerLink]="privacyUrl">{{ 'Footer.Privacy' | translate }}</a>

      @if (contactUrl) {
        <a class="mx-2 text-muted" [attr.href]="contactUrl">{{ 'Footer.Contact' | translate }}</a>
      }
    </div>
  </div>
</footer>
