import { Routes } from '@angular/router';
import { RouteLocations } from './common/routing/route-locations.enum';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';
import { HasCompanyRouteGuard } from './common/routing/route-guards/has-company.guard';
import { EmailValidatedGuard } from './common/routing/route-guards/email-validated.guard';

export const AppRoutes: Routes = [
  {
    path: RouteLocations.Home,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: RouteLocations.About,
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: RouteLocations.CompanyCreation,
    loadChildren: () => import('./company-creation/company-creation.module').then(m => m.CompanyCreationModule),
    canActivate: [
      LoggedInRouteGuard,
      EmailValidatedGuard,
    ],
  },
  {
    path: RouteLocations.Login,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: RouteLocations.Dashboard,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [
      LoggedInRouteGuard,
      EmailValidatedGuard,
      HasCompanyRouteGuard,
    ],
  },
  {
    path: RouteLocations.NotFound,
    loadComponent: () => import('./core/not-found/not-found.component').then(_ => _.NotFoundComponent),
  },
  {
    path: 'privacy',
    redirectTo: [RouteLocations.About, RouteLocations.AboutChildren.PrivacyPolicy].join('/'),
  },
  {
    path: 'terms',
    redirectTo: [RouteLocations.About, RouteLocations.AboutChildren.TermsAndConditions].join('/'),
  },
  {
    path: '**',
    redirectTo: RouteLocations.Home,
  },
];
