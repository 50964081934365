<div
  class="content-center"
  [class.flex-column]="direction === 'vertical'"
>
  @if (!isUserLoggedIn) {
    <a
      class="nav-item"
      [routerLink]="pricingUrl" routerLinkActive="active"
    >
      {{ 'Header.Menu.Pricing' | translate }}
    </a>
  }

  @if (isUserLoggedIn) {
    <a
      class="nav-item"
      [routerLink]="dashboardUrl" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
    >
      {{ 'Header.Menu.Dashboard' | translate }}
    </a>
  }

  <a
    class="nav-item" *featureAvailable="'ViewOrganization'"
    [routerLink]="organizationUrl" routerLinkActive="active"
  >
    {{ 'Header.Menu.Organization' | translate }}
  </a>

  <a
    class="nav-item" *featureAvailable="'Wiki'"
    [routerLink]="wikiUrl" routerLinkActive="active"
  >
    {{ 'Header.Menu.Wiki' | translate }}
  </a>

  <a
    class="nav-item" *featureAvailable="'Goals'"
    [routerLink]="goalsUrl" routerLinkActive="active"
  >
    {{ 'Header.Menu.Goals' | translate }}
  </a>

  <a
    class="nav-item" *featureAvailable="'Surveys'"
    [routerLink]="surveysUrl" routerLinkActive="active"
  >
    {{ 'Header.Menu.Surveys' | translate }}
  </a>

  <a
    class="nav-item" *featureAvailable="'ViewCompanySettings'"
    [routerLink]="companySettingsUrl" routerLinkActive="active"
  >
    {{ 'Header.Menu.Settings' | translate }}
  </a>
</div>
