import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutes } from './app.routes';
import { AppCommonModule } from './common/common.module';
import { AppCoreModule } from './core/core.module';
import { NgxsModule } from '@ngxs/store';
import { ApplicationState } from './app.state';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,

      RouterModule.forRoot(AppRoutes, {
        preloadingStrategy: PreloadAllModules,
      }),

      AppCommonModule.forRoot(),

      AppCoreModule,

      NgxsModule.forRoot([ApplicationState]),

      NgxsSelectSnapshotModule.forRoot(),

      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimations(),
  ],
};
