import { HttpHeader } from '@angular-cool/http';
import { IResponseInterceptor } from '@angular-cool/http/lib/response-interceptor.interface';
import { Injectable } from '@angular/core';
import { UNAUTHORIZED } from 'http-status-codes';
import { AuthenticationService } from '../../../../common/services/authentication.service';

export const API_EXCEPTIONS: RegExp[] = [
  /\/api\/authentications\/local\/login$/,
  /\/api\/authentications\/local\/register$/,
  /\/api\/settings$/,
];

@Injectable()
export class UnauthenticatedInterceptor implements IResponseInterceptor {
  constructor(private _authenticationService: AuthenticationService) {
  }

  public async afterResponseAsync(response: any, url: string, method: string, data: any, headers: HttpHeader[]): Promise<boolean> {
    if (response.status === UNAUTHORIZED && !API_EXCEPTIONS.some(exception => exception.test(response.url))) {
      await this._authenticationService.logoutAsync();

      return true;
    }

    return false;
  }
}
