import { NgModule } from '@angular/core';
import { MessageCampaignsService } from './shared/services/message-campaigns.service';
import { PopupMessageComponent } from './popup-message/popup-message.component';
import { AppCommonModule } from '../../common/common.module';
import { MessageCampaignStateManager } from './shared/services/message-campaign-state.manager';

@NgModule({
  imports: [
    AppCommonModule,
    PopupMessageComponent,
  ],
  providers: [
    MessageCampaignsService,
    MessageCampaignStateManager,
  ],
})
export class MessagingModule {

}
