import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { GlobalErrorHandler } from '../common/services/logger/global-error-handler';
import { StartupService, startupServiceFactory } from './shared/services/startup/startup.service';
import { UnauthenticatedInterceptor } from './shared/services/startup/unauthnticated.interceptor';
import { TranslationService } from './shared/services/translation/translation.service';
import { MessagingModule } from './messaging/messaging.module';
import { AppCommonModule } from '../common/common.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  exports: [],
  imports: [
    AppCommonModule,
    HttpClientModule,
    MessagingModule,
  ],
  providers: [
    StartupService,
    UnauthenticatedInterceptor,
    TranslationService,
    { provide: ErrorHandler, useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler },
    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
  ],
})
export class AppCoreModule {

}
